<template>
  <div class="font-mono w-full px-0">
    <div class="px-0">
      <NuxtLoadingIndicator></NuxtLoadingIndicator>
      <slot />
    </div>
  </div>
</template>
<script setup>
import { ref } from "vue";
useHead({
  title: "deelotto",
  meta: [
    {
      name: "viewport",
      content:
        "width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no",
    },
  ],
  meta: [{ name: "title", content: "deelotto" }],
  meta: [{ name: "description", content: "deelotto เว็บหวย" }],
});
</script>
<style>
html {
  -webkit-text-size-adjust: none;
  touch-action: manipulation;
}
.bg-yellow-500 {
  background: url("~/assets/images/bg-pattern/white.webp") !important;
  background-repeat: repeat;
  background-size: 250px !important;
}
.router-link-exact-active {
  --tw-border-opacity: 1;
  border-color: green;
}
</style>
